import {
  OfferResponse,
  OfferResponseAllPaxServicesItem,
} from '@codegen/offerAPI';

export const getAlyziaGroundHandlingFromAllPaxServices = (
  allPaxServices?: OfferResponseAllPaxServicesItem[],
) =>
  allPaxServices?.find((service) => service.service_class === 'alyzia') || null;

export const updateOfferWithUpdatedAlyziaGroundHandling = ({
  offer,
  quantity,
  serviceId,
}: {
  offer: OfferResponse;
  quantity: number;
  serviceId: string;
}) => {
  return {
    ...offer,
    all_pax_services: offer.all_pax_services.map((service) =>
      service.service_id === serviceId
        ? {
            ...service,
            quantity,
            can_decrement: quantity !== 0,
            can_increment: quantity === 0,
          }
        : service,
    ),
  };
};
