import styled from '@emotion/styled';
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';
import { createTypography } from '../../styles/base';
import { GridRow, GridColumn } from '../Grid/Grid';
import StructuredTextWrapper from './StructuredTextWrapper';

interface Props {
  className?: string;
  data?: Maybe<StructuredTextType>;
}

const StyledGridRow = styled(GridRow)(({ theme: { typography } }) =>
  createTypography(typography.body01),
);

const StructuredParagraphWrapper = ({ className, data }: Props) => {
  return (
    <StyledGridRow className={className} display="block">
      <GridColumn>
        <StructuredTextWrapper data={data} />
      </GridColumn>
    </StyledGridRow>
  );
};

export default StructuredParagraphWrapper;
