import { css } from '@emotion/react';

const laCompagnieFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Roboto Condensed';
    src: url('/fonts/LaCompagnie/RobotoCondensed-Bold.ttf');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Titillium Web';
    src: url('/fonts/LaCompagnie/TitilliumWeb-Light.woff2') format('truetype');
  }

  @font-face {
    font-display: fallback;
    font-family: TitilliumWeb-Bold;
    src: url('/fonts/LaCompagnie/TitilliumWeb-Bold.woff2') format('truetype');
  }

  @font-face {
    font-display: fallback;
    font-family: TitilliumWeb-SemiBold;
    src: url('/fonts/LaCompagnie/TitilliumWeb-SemiBold.woff2')
      format('truetype');
  }
`;

export default laCompagnieFonts;
