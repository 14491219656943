import type { CSSProperties } from 'react';
// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageLoaderProps, ImageProps } from 'next/image';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createCSSFilterFromHex } from '../styles/base';

type BaseProps = Omit<ImageProps, 'src' | 'alt'> & {
  alt?: Maybe<string>;
  color?: string;
  focalPoint?: Maybe<{ x: number; y: number }>;
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: CSSProperties['objectPosition'];
  src: string;
};

type WithWidthAndHeight = Omit<BaseProps, 'width' | 'height' | 'fill'> & {
  fill?: false;
  height?: Maybe<ImageProps['height']>;
  width?: Maybe<ImageProps['width']>;
};

type WithoutWidthAndHeight = Omit<BaseProps, 'width' | 'height' | 'fill'> & {
  fill: true;
  height?: never;
  width?: never;
};

type Props = WithWidthAndHeight | WithoutWidthAndHeight;

const StyledNextImage = styled(NextImage, {
  shouldForwardProp: (prop) =>
    prop !== 'fit' && prop !== 'color' && prop !== 'position',
})<{
  color: Props['color'];
  fit: Props['objectFit'];
  position: Props['objectPosition'];
}>(({ color, fit, position }) => [
  color && createCSSFilterFromHex(color),
  css`
    object-fit: ${fit};
    object-position: ${position};
  `,
]);

const customLoader = ({ quality, src, width }: ImageLoaderProps) =>
  src.includes('svg')
    ? src
    : `${src}?w=${width}&q=${quality || 100}&auto=format`;

const Image = ({
  alt,
  color,
  fill,
  focalPoint,
  height,
  objectFit,
  priority,
  src,
  width,
  ...imageProps
}: Props) => {
  const isSvg = src.includes('.svg');

  const position = focalPoint
    ? `${focalPoint.x * 100}% ${focalPoint.y * 100}%`
    : '50% 50%';

  return (
    <StyledNextImage
      {...imageProps}
      alt={alt || ''}
      color={color}
      fill={fill}
      fit={isSvg ? 'contain' : objectFit}
      height={fill ? undefined : height ?? 0}
      loader={customLoader}
      position={position}
      priority={priority}
      src={src}
      unoptimized={isSvg}
      width={fill ? undefined : width ?? 0}
    />
  );
};

export default Image;
