import { RUNTIME_ENV } from '@shared/types/enums';

export const CURRENT_DATOCMS_ENV =
  process.env['NEXT_PUBLIC_DATO_CMS_ENVIRONMENT'];

export const cmsFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers = {} as RequestInit['headers'],
): (() => Promise<TData>) => {
  const url = 'https://graphql.datocms.com';

  return async (): Promise<TData> => {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer c9db0efa630a94b16531257c1dfb4a',
        'X-Exclude-Invalid': 'true',
        ...(CURRENT_DATOCMS_ENV && { 'X-ENVIRONMENT': CURRENT_DATOCMS_ENV }),
        ...(process.env['NEXT_PUBLIC_RUNTIME_ENV'] !== RUNTIME_ENV.production
          ? { 'X-Include-Drafts': 'true' }
          : {}),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const [{ message }] = json.errors;

      throw new Error(message);
    }

    return json.data;
  };
};
