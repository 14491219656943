import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText } from 'datocms-structured-text-utils';
import { ColorFragment, ContentFragment } from '@codegen/cmsUtils';
import { Language } from '@shared/types/enums';
import { legacySpacings } from '@ui-v2/theme/layout';
import { createCSSFilterFromHex, createTypography } from '../../styles/base';
import IframeWrapper from '../IframeWrapper/IframeWrapper';
import StructuredParagraphWrapper from './StructuredParagraph';

interface Props {
  content: ContentFragment[];
  locale: Language;
  residency: string;
}

const Container = styled.div(({ theme: { colours } }) => [
  css`
    display: flex;
    flex-direction: column;
    color: ${colours.text.default};
  `,
]);

export const Paragraph = styled.p(
  ({ theme: { colours, spacings, typography } }) => [
    createTypography(typography.body01),
    css`
      margin-top: ${spacings['8']}px;
      color: ${colours.text.default};
    `,
  ],
);

const StyledImg = styled.img<{ imageColor?: Maybe<ColorFragment> }>(
  ({ imageColor, theme: { colours, spacings } }) => [
    createCSSFilterFromHex(imageColor?.main || colours.text.default),
    css`
      width: 25px;
      max-width: 25px;
      height: 25px;
      max-height: 25px;
      margin-right: ${spacings['16']}px;
    `,
  ],
);

export const Title = styled.h3(() => [
  css`
    display: flex;
    align-items: center;
    margin-top: ${legacySpacings.Medium}px;
  `,
]);

const StructuredParagraph = styled(StructuredParagraphWrapper)(
  () => css`
    margin-top: ${legacySpacings.Medium}px;
  `,
);

const StyledIframe = styled(IframeWrapper)(
  ({ theme: { spacings } }) => css`
    margin-top: ${spacings['48']}px;
  `,
);

const StandaloneContent = ({ content, locale, residency }: Props) => {
  const components = content
    .map((item) => {
      switch (item.__typename) {
        case 'TitleRecord':
          return (
            <Title key={item.id}>
              {item.titleImage?.url && <StyledImg src={item.titleImage.url} />}
              {item.title.value}
            </Title>
          );
        case 'ParagraphRecord':
          return <Paragraph key={item.id}>{item.text.value}</Paragraph>;
        case 'StructuredParagraphRecord':
          return (
            <StructuredParagraph
              data={item.content?.content as Maybe<StructuredText>}
              key={`${item.id}StructuredParagraph`}
            />
          );
        case 'IframeRecord':
          return (
            <StyledIframe
              identifier={item.identifier}
              key={`${item.id}Iframe`}
              localeString={`${locale}-${residency}`}
            />
          );
        default:
          return null;
      }
    })
    .filter((comp) => comp);

  return <Container>{components}</Container>;
};

export default StandaloneContent;
