import { Interpolation, Theme } from '@emotion/react';
import {
  isHeading,
  isLink,
  StructuredText as StructuredTextType,
} from 'datocms-structured-text-utils';
import { renderNodeRule, StructuredText } from 'react-datocms';
import Link from '../Link/Link';

interface Props {
  className?: string;
  data?: Maybe<StructuredTextType>;
  h1Styles?: Interpolation<Theme>;
  h2Styles?: Interpolation<Theme>;
  h3Styles?: Interpolation<Theme>;
  h4Styles?: Interpolation<Theme>;
  h5Styles?: Interpolation<Theme>;
  h6Styles?: Interpolation<Theme>;
  linkStyles?: Interpolation<Theme>;
}

const StructuredTextWrapper = ({
  data,
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  h6Styles,
  linkStyles,
}: Props) => {
  return (
    <StructuredText
      customNodeRules={[
        renderNodeRule(isLink, ({ children, key, node }) => {
          return (
            <Link
              css={linkStyles}
              href={node.url}
              key={key}
              {...node.meta?.reduce(
                (acc, { id, value }) => ({ ...acc, [id]: value }),
                {},
              )}
            >
              {children}
            </Link>
          );
        }),
        renderNodeRule(isHeading, ({ children, key, node }) => {
          if (node.level === 1) {
            return (
              <h1 css={h1Styles} key={key}>
                {children}
              </h1>
            );
          }

          if (node.level === 2) {
            return (
              <h2 css={h2Styles} key={key}>
                {children}
              </h2>
            );
          }

          if (node.level === 3) {
            return (
              <h3 css={h3Styles} key={key}>
                {children}
              </h3>
            );
          }

          if (node.level === 4) {
            return (
              <h4 css={h4Styles} key={key}>
                {children}
              </h4>
            );
          }

          if (node.level === 5) {
            return (
              <h5 css={h5Styles} key={key}>
                {children}
              </h5>
            );
          }

          return (
            <h6 css={h6Styles} key={key}>
              {children}
            </h6>
          );
        }),
      ]}
      data={data}
    />
  );
};

export default StructuredTextWrapper;
