import { v4 as uuidv4 } from 'uuid';

export const withUniqueID = <Type>(array: Type[]) =>
  array.map((value) => {
    const id = uuidv4();

    return { id, ...value };
  });

export const head = <T>(array: T[]): T | null => {
  if (array.length === 0) {
    return null;
  }

  return array[0] as T;
};

export const tail = <T>(array: T[]): T | null => {
  if (array.length === 0) {
    return null;
  }

  return array[array.length - 1] as T;
};

export const generateNumberArray = (startValue: number, endValue: number) => {
  if (endValue < startValue) {
    return [];
  }

  return Array.from({ length: endValue - startValue + 1 }).map(
    (_, k) => k + startValue,
  );
};

export const sortArray = <T, K extends keyof T>({
  array,
  key,
  reverse,
  sortArray,
}: {
  array: T[];
  key: K;
  reverse?: boolean;
  sortArray?: T[K][];
}) => {
  const sortedArray = [...array].sort((a, b) => {
    const x = a[key];
    const y = b[key];

    if (sortArray && sortArray.length > 0) {
      const xIndex = sortArray.indexOf(x);
      const yIndex = sortArray.indexOf(y);

      return xIndex - yIndex;
    }

    if (x < y) {
      return -1;
    } else if (x > y) {
      return 1;
    }

    return 0;
  });

  return reverse ? [...sortedArray].reverse() : sortedArray;
};

export const removeDuplicateKeyFromObject = <T, K extends keyof T>(
  array: T[],
  key: K,
): T[] => {
  return array.reduce<T[]>((acc, curr) => {
    if (acc.some((item) => item[key] === curr[key])) {
      return acc;
    }

    return [...acc, curr];
  }, []);
};
