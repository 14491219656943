import StandaloneContentModal from '@ui/components/Content/StandaloneContentModal';
import useAlyziaGroundHandling from '@web/components/Booking/hooks/useAlyziaGroundHandling';
import { useConstants } from '@web/context/ConstantContext';
import useGetIcon from '@web/context/hooks/useGetIcon';
import useStandaloneContent from '@web/context/hooks/useStandaloneContent';
import { useSettings } from '@web/context/SettingsContext';

const CONTENT_IDENTIFYER = 'alyzia-information';

const AlyziaGroundHandlingInformationModal = ({
  isOpen,
  toggleModal,
}: {
  isOpen: boolean;
  toggleModal: () => void;
}) => {
  const { locale } = useConstants();
  const { residency } = useSettings();

  const closeIcon = useGetIcon()('closeIcon');

  const { content } = useStandaloneContent('alyzia-information');

  const { name } = useAlyziaGroundHandling();

  return (
    <StandaloneContentModal
      closeIcon={closeIcon}
      content={content}
      id={CONTENT_IDENTIFYER}
      isOpen={isOpen}
      locale={locale}
      residency={residency}
      title={name}
      toggleModal={toggleModal}
    />
  );
};

export default AlyziaGroundHandlingInformationModal;
