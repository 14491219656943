import dayjs from 'dayjs';
import { TransProps } from 'next-translate';
import { Vendor } from '@codegen/cmsTypes';
import {
  Airport,
  OfferFragment,
  RouteFragment,
  ItineraryFragment,
  LegFragment,
  Route,
  Itinerary,
  Maybe,
} from '@codegen/gatewayUtils';
import { VendorType } from '@shared/types/enums';

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;

export const getDuration = (seconds: number) => {
  const days =
    seconds >= SECONDS_IN_DAY ? `${Math.floor(seconds / SECONDS_IN_DAY)}` : '';

  const hours = `${Math.floor((seconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR)}`;

  const minutes = (
    '0' + Math.floor((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE)
  ).slice(-2);

  return [days, hours, minutes];
};

export const getStopsFromRoutes = (
  routes: OfferFragment['itinerary']['outbound'],
) => {
  return routes.reduce<Airport[]>((stops, route, iRoute) => {
    const { legs } = route;
    const isLastRoute = iRoute === routes.length - 1;

    const legsStops = legs.reduce<Airport[]>((airports, leg, iLeg) => {
      const isLastLeg = iLeg === legs.length - 1;

      if (isLastRoute && isLastLeg) {
        return airports;
      }

      return [...airports, leg.destination];
    }, []);

    return [...stops, ...legsStops];
  }, []);
};

export const getNextLeg = (
  routes: RouteFragment[],
  iRoute: number,
  iLeg: number,
) => {
  if (iRoute >= routes.length) {
    return null;
  }

  const currentRoute = routes[iRoute] as RouteFragment;
  const { legs } = currentRoute;

  const isLastRoute = iRoute === routes.length - 1;
  const isLastLeg = iLeg === legs.length - 1;

  if (!isLastLeg) {
    return legs[iLeg + 1];
  }

  if (!isLastRoute) {
    return routes[iRoute + 1]?.legs[0];
  }

  return null;
};

export const getPreviousLeg = (
  routes: RouteFragment[],
  iRoute: number,
  iLeg: number,
) => {
  if (iRoute < 0 || iRoute >= routes.length) {
    return null;
  }

  const isFirstRoute = iRoute === 0;
  const isFirstLeg = iLeg === 0;

  if (!isFirstLeg) {
    const currentRoute = routes[iRoute] as RouteFragment;

    return currentRoute.legs[iLeg - 1];
  }

  if (!isFirstRoute) {
    const previousRoute = routes[iRoute - 1] as RouteFragment;

    return previousRoute.legs[previousRoute.legs.length - 1];
  }

  return null;
};

export const getStopDuration = (
  routes: RouteFragment[],
  iRoute: number,
  iLeg: number,
) => {
  if (iRoute >= routes.length) {
    return null;
  }
  const currentRoute = routes[iRoute];

  if ((currentRoute && iLeg >= currentRoute.legs.length) || !currentRoute) {
    return null;
  }

  const currentLeg = currentRoute.legs[iLeg];
  const nextLeg = getNextLeg(routes, iRoute, iLeg);

  if (!nextLeg) {
    return null;
  }

  const stopStart = currentLeg?.arrival;
  const stopEnd = nextLeg.departure;

  return stopEnd.diff(stopStart, 'second');
};

export const getIatasFromRoutes = (
  routes: OfferFragment['itinerary']['outbound'],
) => {
  return Array.from(
    new Set(
      routes.reduce<string[]>(
        (acc, route) => [
          ...acc,
          route.marketingCarrier.code,
          route.operatingCarrier.code,
        ],
        [],
      ),
    ),
  );
};

const parseGatewayLegs = (legs: LegFragment[]) => {
  return legs.map((leg) => ({
    ...leg,
    departure: dayjs(leg.departure),
    arrival: dayjs(leg.arrival),
  }));
};

const parseGatewayRoute = (routes: RouteFragment[]) => {
  return routes.map((route) => ({
    ...route,
    departure: dayjs(route.departure),
    arrival: dayjs(route.arrival),
    legs: parseGatewayLegs(route.legs),
  }));
};

export const parseGatewayItinerary = (itinerary?: ItineraryFragment) => {
  if (!itinerary) {
    return { outbound: [], homebound: [] };
  }

  return {
    outbound: parseGatewayRoute(itinerary.outbound),
    homebound: parseGatewayRoute(itinerary.homebound),
  };
};

export const getIsTrain = (carrierType: Maybe<string>) =>
  carrierType?.includes('TRN');

export const formatStationName = (station: Airport, vendor?: Vendor) => {
  const isRail = vendor && vendor.vendorType === VendorType.RAIL;

  if (isRail) {
    return station.name;
  }

  return `${station.city} (${station.code})`;
};

export const getConnectionInformation = ({
  dohopConnectName,
  hasNoCheckedBagsConnection,
  hasOvernightStay,
  isNextRouteAtDifferentStation,
  nextLegIsTrain,
}: {
  dohopConnectName: string;
  hasNoCheckedBagsConnection?: boolean;
  hasOvernightStay?: boolean;
  isNextRouteAtDifferentStation: boolean;
  nextLegIsTrain?: boolean;
}): TransProps[] => {
  return [
    ...(!hasNoCheckedBagsConnection
      ? [{ i18nKey: 'Collect your baggage' }]
      : []),
    ...(nextLegIsTrain
      ? [{ i18nKey: 'Go to the next train station (at your expense)' }]
      : [{ i18nKey: 'Check in for your next flight' }]),
    ...(hasOvernightStay
      ? [{ i18nKey: 'Overnight accommodation (at your expense)' }]
      : []),
    ...(isNextRouteAtDifferentStation
      ? [{ i18nKey: 'Go to the next airport (at your expense)' }]
      : []),
    {
      i18nKey: 'dohop-connect-link',
      values: {
        dohopConnectName,
      },
      defaultTrans: `Connection is protected by ${dohopConnectName}`,
    },
  ];
};

export const getCarriersFromItinerary = (itinerary: ItineraryFragment) =>
  getIatasFromRoutes([...itinerary.outbound, ...itinerary.homebound]);

const NO_CHECKED_BAGS_CONNECTION_MAX_DURATION = 5340; // 89 minutes

export const hasAStopDurationShorterThanMaxDuration = (routes: Route[]) => {
  return (
    routes.filter((route, index) => {
      const duration = getStopDuration(routes, index, route.legs.length - 1);

      return (
        typeof duration === 'number' &&
        duration <= NO_CHECKED_BAGS_CONNECTION_MAX_DURATION
      );
    }).length > 0
  );
};

/**
 *
 * @param itinerary An itinerary
 * @returns Whether the itinerary has a "No checked bags connection"
 * A no checked bags connection is a connection verified by the dohop connection service
 * that has a very short connection time and does not allow the user to check in bags.
 * These connections have a range of 60-89 minutes.
 */
export const offerhasNoCheckedBagsConnection = (itinerary: Itinerary) =>
  hasAStopDurationShorterThanMaxDuration(itinerary.outbound) ||
  hasAStopDurationShorterThanMaxDuration(itinerary.homebound);
