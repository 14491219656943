import { useEffect } from 'react';
import { buttonClicked, linkClicked } from '@web/utils/analytics/Avo';

const findParentWithClickListener = (
  element: HTMLElement,
): HTMLElement | null => {
  if (!element.parentElement) {
    return null;
  }

  const { parentElement } = element;

  if (parentElement.onclick) {
    return parentElement;
  }

  return findParentWithClickListener(parentElement);
};

const triggerEvent = (element: HTMLElement | null) => {
  if (!element) {
    return;
  }

  const id = element.getAttribute('data-cy');

  if (element.tagName === 'BUTTON' && id && element.onclick) {
    buttonClicked({ id });
  } else if (element.tagName === 'A') {
    const url = element.getAttribute('href');
    linkClicked({ url });
  }
};

const useCreateGlobalAnalyticsClickHandlers = () => {
  useEffect(() => {
    const handleButtonClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (target.onclick) {
        triggerEvent(target);

        return;
      }

      const clickedEventElement = findParentWithClickListener(target);

      triggerEvent(clickedEventElement);
    };

    document.addEventListener('click', handleButtonClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleButtonClick, {
        capture: true,
      });
    };
  }, []);
};

export default useCreateGlobalAnalyticsClickHandlers;
