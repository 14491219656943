import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridRow, GridWrap, GridColumn } from '../Grid/Grid';

export interface Props {
  error: Maybe<Error>;
  showErrorMessage?: boolean;
  text: string;
}

const ErrorText = styled.div(
  ({ theme: { colours } }) => css`
    color: ${colours.text.critical};
    text-align: center;
  `,
);

const Wrapper = styled.div(
  ({ theme: { colours, spacings } }) => css`
    padding: ${spacings['16']}px;
    background: ${colours.surface.critical};
  `,
);

const DefaultErrorView = ({ error, showErrorMessage, text }: Props) => (
  <Wrapper>
    <GridWrap>
      <GridRow display="block">
        <GridColumn>
          <ErrorText>{text}</ErrorText>
          {showErrorMessage && error && (
            <ErrorText>Error: {error.message}</ErrorText>
          )}
        </GridColumn>
      </GridRow>
    </GridWrap>
  </Wrapper>
);

export default DefaultErrorView;
