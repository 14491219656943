import styled from '@emotion/styled';
import facepaint from 'facepaint';
import { DisplayType } from '@shared/types/enums';
import { legacyBreakpoints } from '@ui-v2/theme/layout';
import { mqMin } from '../../styles/base';

interface Props {
  fromDisplay?: DisplayType;
  toDisplay?: DisplayType;
}

export const mediaQuery = facepaint([
  mqMin.ExtraExtraSmall,
  mqMin.ExtraSmall,
  mqMin.Small,
  mqMin.Medium,
  mqMin.Large,
  mqMin.ExtraLarge,
  mqMin.ExtraExtraLarge,
]);

const getDisplayValue = (
  displayType: DisplayType,
  fromDisplay?: DisplayType,
  toDisplay?: DisplayType,
) => {
  if (
    (fromDisplay && displayType === fromDisplay) ||
    (toDisplay && legacyBreakpoints[displayType] < legacyBreakpoints[toDisplay])
  ) {
    return 'block';
  }

  if (
    (fromDisplay &&
      legacyBreakpoints[displayType] < legacyBreakpoints[fromDisplay]) ||
    (toDisplay && displayType === toDisplay)
  ) {
    return 'none';
  }

  return undefined;
};

const MediaQuery = styled.div<Props>(({ fromDisplay, toDisplay }) =>
  mediaQuery({
    display: [
      getDisplayValue(DisplayType.ExtraExtraSmall, fromDisplay, toDisplay),
      getDisplayValue(DisplayType.ExtraSmall, fromDisplay, toDisplay),
      getDisplayValue(DisplayType.Small, fromDisplay, toDisplay),
      getDisplayValue(DisplayType.Medium, fromDisplay, toDisplay),
      getDisplayValue(DisplayType.Large, fromDisplay, toDisplay),
      getDisplayValue(DisplayType.ExtraLarge, fromDisplay, toDisplay),
      getDisplayValue(DisplayType.ExtraExtraLarge, fromDisplay, toDisplay),
    ],
  }),
);

export default MediaQuery;
