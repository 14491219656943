import useTranslation from 'next-translate/useTranslation';
import { Summary } from '@codegen/offerAPI';
import useGetIcon from '@web/context/hooks/useGetIcon';
import useVendors from '@web/context/hooks/useVendors';
import {
  getAdditionalServices,
  getCombinedAdditionalServicesFromSummary,
} from '@web/utils/booking/serviceUtils';
import {
  getIatasFromSummary,
  getSelectedBundlesFromSummary,
} from '@web/utils/booking/summaryUtils';

export const useSummary = ({ summary }: { summary?: Summary }) => {
  const { t } = useTranslation();
  const getFallbackIcon = useGetIcon();
  const iatas = getIatasFromSummary(summary);

  const { isError, isLoading, vendors } = useVendors({
    iatas,
    includeBookingData: true,
  });

  const getAdditionalServicesByLegId = getAdditionalServices({
    summary,
    vendors,
    t,
    getFallbackIcon,
  });

  const allAdditionalServices = summary?.leg_summaries.flatMap((legSummary) =>
    legSummary.legs.flatMap((leg) => getAdditionalServicesByLegId(leg.leg_id)),
  );

  const bundles =
    !isLoading && !isError
      ? getSelectedBundlesFromSummary({
          t,
          summary,
          vendors,
          getFallbackIcon,
        })
      : [];

  const getSelectedBundleByCode = (code: string) =>
    bundles.find((bundle) => bundle.code === code);

  return {
    isSummaryLoading: isLoading,
    selectedBundles: bundles,
    getSelectedBundleByCode,
    allAdditionalServices: getCombinedAdditionalServicesFromSummary(
      allAdditionalServices,
    ),
    getAdditionalServicesByLegId,
  };
};
