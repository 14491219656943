import { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorFragment, ImageFragment } from '@codegen/cmsUtils';
import Box from '@ui-v2/core/Box/Box';
import Container from '@ui-v2/core/Container/Container';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import { centerVertically, hideDuringPrint } from '../../styles/base';

export interface Props {
  backgroundColor?: Maybe<ColorFragment>;
  children: ReactNode;
  className?: string;
  image: Maybe<ImageFragment>;
}

const StyledHeaderBar = styled.header<{
  backgroundColor?: Maybe<ColorFragment>;
  image?: Maybe<ImageFragment>;
}>(({ image, theme: { colours } }) => [
  centerVertically,
  hideDuringPrint,
  css`
    position: relative;
    z-index: ${legacyZIndices.z1};
    padding: 12px 0;
    background: ${colours.components.header.surface};
    color: ${colours.components.header.text};
  `,
  image &&
    css`
      background-image: url(${image.url});
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
    `,
]);

const HeaderBar = ({ children, className, image }: Props) => {
  return (
    <StyledHeaderBar className={className} image={image}>
      <Container>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          width="full"
        >
          {children}
        </Box>
      </Container>
    </StyledHeaderBar>
  );
};

export default HeaderBar;
