import { ThemeV4Fragment } from '@codegen/cmsUtils';
import { ThemeColours } from '@ui-v2/types/colours';

export const buildColours = (theme: ThemeV4Fragment): ThemeColours => {
  const { colours } = theme;

  return {
    components: {
      date: {
        text: {
          default: colours.dateTextDefault,
          disabled: colours.dateTextDisabled,
          selected: colours.dateTextSelected,
        },
      },
      slider: {
        interactive: {
          fill: colours.sliderInteractiveFill,
          handle: colours.sliderInteractiveHandle,
          track: colours.sliderInteractiveTrack,
        },
      },
      seat: {
        border: {
          extraLegroom: colours.seatBorderExtraLegroom,
          standard: colours.seatBorderStandard,
          unavailable: colours.seatBorderUnavailable,
          upFront: colours.seatBorderUpFront,
        },
        surface: {
          extraLegroom: colours.seatSurfaceExtraLegroom,
          standard: colours.seatSurfaceStandard,
          unavailable: colours.seatSurfaceUnavailable,
          upFront: colours.seatSurfaceUpFront,
          selected: colours.seatSurfaceSelected,
        },
      },
      header: {
        surface: colours.headerSurface,
        text: colours.headerText,
      },
      navBar: {
        surface: {
          default: colours.navBarSurfaceDefault,
        },
        text: colours.navBarText,
      },
      search: {
        interactive: {
          default: colours.searchInteractiveDefault,
          hover: colours.searchInteractiveHover,
          pressed: colours.searchInteractivePressed,
        },
        surface: {
          default: colours.searchSurfaceDefault,
        },
        text: {
          on: {
            interactive: colours.searchTextOnInteractive,
          },
        },
      },
      toggle: {
        interactive: {
          default: colours.interactiveToggleDefault,
          hover: colours.interactiveToggleHover,
          active: colours.interactiveToggleActive,
        },
      },
    },
    brand: {
      primary: colours.brandPrimary,
      secondary: colours.brandSecondary,
      tertiary: colours.brandTertiary,
      accent: colours.brandAccent,
    },
    background: {
      main: colours.backgroundMain,
      backdrop: colours.backgroundBackdrop,
    },
    border: {
      default: colours.borderDefault,
      success: colours.borderSuccess,
      critical: colours.borderCritical,
      interactive: colours.borderInteractive,
      information: colours.borderInformation,
      warning: colours.borderWarning,
      inverse: colours.borderInverse,
      strong: colours.borderStrong,
      subdued: colours.borderSubdued,
      logo: colours.logoBorder,
    },
    icons: {
      default: colours.iconDefault,
      disabled: colours.iconDisabled,
      inverse: colours.iconInverse,
      subdued: colours.iconSubdued,
      on: {
        success: colours.iconOnSuccess,
        critical: colours.iconOnCritical,
        information: colours.iconOnInformation,
        primary: {
          default: colours.iconOnPrimaryDefault,
          inverse: colours.iconOnPrimaryInverse,
        },
        secondary: {
          default: colours.iconOnSecondaryDefault,
          inverse: colours.iconOnSecondaryInverse,
        },
        tertiary: {
          default: colours.iconOnTertiaryDefault,
          inverse: colours.iconOnTertiaryInverse,
        },
        warning: colours.iconOnWarning,
      },
    },
    interactive: {
      focused: colours.interactiveFocused,
      primary: {
        default: colours.interactivePrimaryDefault,
        hover: colours.interactivePrimaryHover,
        pressed: colours.interactivePrimaryPressed,
        inverse: {
          default: colours.interactivePrimaryInverseDefault,
          hover: colours.interactivePrimaryInverseHover,
          pressed: colours.interactivePrimaryInversePressed,
        },
      },
      secondary: {
        default: colours.interactiveSecondaryDefault,
        hover: colours.interactiveSecondaryHover,
        pressed: colours.interactiveSecondaryPressed,
        border: colours.interactiveSecondaryBorder,
        inverse: {
          hover: colours.interactiveSecondaryInverseHover,
        },
      },
      tertiary: {
        hover: colours.interactiveTertiaryHover,
        inverse: {
          hover: colours.interactiveTertiaryInverseHover ?? '',
        },
      },
    },
    surface: {
      critical: colours.surfaceCritical,
      default: colours.surfaceDefault,
      disabled: colours.surfaceDisabled,
      hover: colours.surfaceHover,
      information: colours.surfaceInformation,
      inverse: colours.surfaceInverse,
      pressed: colours.surfacePressed,
      success: colours.surfaceSuccess,
      warning: colours.surfaceWarning,
      decorative: colours.surfaceDecorative,
      secondary: colours.surfaceSecondary,
      link: {
        hover: colours.surfaceLinkHover,
      },
      main: colours.surfaceMain,
      selected: {
        default: colours.surfaceSelectedDefault,
        hover: colours.surfaceSelectedHover,
        pressed: colours.surfaceSelectedPressed,
        subdued: colours.surfaceSelectedSubdued,
      },
    },
    text: {
      inverse: colours.textInverse,
      default: colours.textDefault,
      subdued: colours.textSubdued,
      critical: colours.textCritical,
      disabled: colours.textDisabled,
      heading: colours.textHeading,
      link: {
        default: colours.textLinkDefault,
        hover: colours.textLinkHover,
        pressed: colours.textLinkPressed,
      },
      on: {
        success: colours.textOnSuccess,
        critical: colours.textOnCritical,
        warning: colours.textOnWarning,
        information: colours.textOnInformation,
        interactive: {
          primary: {
            default: colours.textOnInteractivePrimaryDefault,
            inverse: colours.textOnInteractivePrimaryInverse,
          },
          secondary: {
            default: colours.textOnInteractiveSecondaryDefault,
            inverse: colours.textOnInteractiveSecondaryInverse,
          },
          tertiary: {
            default: colours.textOnInteractiveTertiaryDefault,
            inverse: colours.textOnInteractiveTertiaryInverse,
          },
          toggle: {
            default: colours.textOnInteractiveToggleDefault,
            active: colours.textOnInteractiveToggleActive,
          },
        },
      },
    },
  };
};
