export enum Route {
  Baggages = '/baggages',
  BotDetect = '/botDetected',
  Confirmation = '/confirmation',
  CreateOrder = '/createOrder',
  Error = '/500',
  Fares = '/fares',
  FlightGrid = '/flightgrid',
  Index = '/',
  MyBooking = '/my-booking',
  NotFound = '/404',
  PassengerFares = '/passengerfares',
  Passengers = '/passengers',
  Search = '/search',
  Seats = '/seats',
  Summary = '/summary',
}
