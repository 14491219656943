import usePartnerPassengersConfig from '@web/context/hooks/usePassengersConfig';
import useVendors from '@web/context/hooks/useVendors';
import { getVendorPassengerRules } from '@web/utils/booking/passengerUtils';

const useVendorPassengerRules = (iatas: string[]) => {
  const { passengerRules } = usePartnerPassengersConfig();
  const { vendors } = useVendors({ iatas, includeBookingData: true });

  // If the vendor hasn't set passenger rules we default to the partner
  return {
    vendorPassengerRules: getVendorPassengerRules(vendors) || passengerRules,
    enforceUniqueFullName: vendors.some(
      (vendor) => vendor.passengerValidation?.enforceUniqueFullName,
    ),
  };
};

export default useVendorPassengerRules;
