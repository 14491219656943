import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import { hexToCSSFilter } from 'hex-to-css-filter';
import {
  SpecificTypographyFragment,
  TypographyStyleFragment,
} from '@codegen/cmsUtils';
import { DisplayType } from '@shared/types/enums';
import { legacyBreakpoints } from '@ui-v2/theme/layout';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import { isHex } from '@ui-v2/utils/styleUtils';

export const mqPrint = '@media print';

export const hideDuringPrint = css`
  ${mqPrint} {
    display: none;
  }
`;

export const mqMin = Object.keys(legacyBreakpoints).reduce(
  (mq, breakpointName) => ({
    ...mq,
    [breakpointName]: `@media (min-width: ${
      legacyBreakpoints[breakpointName as DisplayType]
    }px)`,
  }),
  {} as { [key in DisplayType]: string },
);

export const mqMax = Object.keys(legacyBreakpoints).reduce(
  (mq, breakpointName) => ({
    ...mq,
    [breakpointName]: `@media (max-width: ${
      legacyBreakpoints[breakpointName as DisplayType] - 1
    }px)`,
  }),
  {} as { [key in DisplayType]: string },
);

export const clampLines = (numberOfLines: number) => css`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${numberOfLines};
  visibility: visible;
`;

export const createLegacyTypography = (
  typography: SpecificTypographyFragment,
) => css`
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSize};
  font-weight: ${typography.fontWeight};
  letter-spacing: ${typography.letterSpacing};
  line-height: ${typography.lineHeight || 'initial'};
  text-decoration: ${typography.textDecoration};
  text-decoration-color: ${typography.textDecorationColor};
  text-transform: ${typography.textTransform};
`;

export const createTypography = (typography: TypographyStyleFragment) => {
  /**
   * Probably a mismatch between the new theme and old configs
   * Old configs set font sizes as a string that includes pixels
   */
  const normalizedFontSize =
    typeof typography.fontSize === 'string' &&
    (typography.fontSize as string).includes('px')
      ? typography.fontSize
      : `${typography.fontSize}px`;

  return css`
    font-family: '${typography.fontFamily}';
    font-size: ${normalizedFontSize};
    font-weight: ${typography.fontWeight};
    letter-spacing: ${typography.letterSpacing};
    line-height: ${typography.lineHeightPercent
      ? `${typography.lineHeightPercent}%`
      : 'initial'};
    text-transform: ${typography.textTransform};
  `;
};

export const centerVertically = css`
  display: flex;
  align-items: center;
`;

export const centerHorizontally = css`
  display: flex;
  justify-content: center;
`;

export const centerContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const loadingAnimation = (
  diameter: number,
  color: string = '#fff',
) => css`
  position: relative;
  color: transparent;

  &[aria-disabled='true'],
  &[aria-disabled='true']:hover,
  &[aria-disabled='true']:focus {
    color: transparent;
  }

  &::after {
    position: absolute;
    z-index: ${legacyZIndices.z1};
    top: calc(50% - ${diameter / 2}px);
    left: calc(50% - ${diameter / 2}px);
    width: ${diameter}px;
    height: ${diameter}px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-right-color: ${color};
    animation: loading-anim 1.5s linear infinite;
    content: '';
  }

  @keyframes loading-anim {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

/**
 * Note: The hexToCSSFilter library seems to have
 * limited SSR support. The values generated differ between
 * server and client and results in a class name mismatch.
 */
export const createCSSFilterFromHex = (color: string) =>
  isHex(color) &&
  css`
    color-interpolation-filters: srgb;
    filter: ${hexToCSSFilter(color).filter};
    ${hideFromPercy};
    transform: translateZ(0);
  `;

export const setCursorOnHover = css`
  &:hover {
    cursor: pointer;
  }
`;

export const shouldForwardProp = ({
  excludeProps,
  prop,
}: {
  excludeProps?: string[];
  prop: string;
}) => isPropValid(prop) && !excludeProps?.includes(prop);

export const resetList = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const mqPercy = '@media only percy';

export const hideFromPercy = css`
  ${mqPercy} {
    visibility: hidden;
  }
`;
