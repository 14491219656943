import { Theme } from '@emotion/react';
import facepaint from 'facepaint';
import { DisplayType } from '@shared/types/enums';
import { legacyBreakpoints } from '@ui-v2/theme/layout';
import { AlignProp, JustifyProp, WidthProp, SpacingProp } from './gridTypes';

export const mq = () => {
  const mediaQueries: string[] = Object.values(legacyBreakpoints).map(
    (breakpoint: number) => `@media(min-width: ${breakpoint}px)`,
  );

  return facepaint(mediaQueries, { overlap: true });
};

export const alignCss = (align: AlignProp | undefined) => {
  if (!align) {
    return null;
  }

  const map = {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  };

  return Array.isArray(align)
    ? align.map((key) => (key !== null ? map[key] : null))
    : map[align];
};

export const justifyCss = (justify: JustifyProp | undefined) => {
  if (!justify) {
    return null;
  }

  const map = {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
    between: 'space-between',
    around: 'space-around',
    evenly: 'space-evenly',
  };

  return Array.isArray(justify)
    ? justify.map((key) => (key !== null ? map[key] : null))
    : map[justify];
};

export const widthCss = (width: WidthProp | undefined) => {
  if (width === undefined) {
    return null;
  }

  const percentage = (columnWidth: number | null) =>
    columnWidth !== null ? `${(columnWidth / 12) * 100}%` : null;

  if (Array.isArray(width)) {
    return width.map((value) =>
      typeof value === 'string' ? value : percentage(value),
    );
  }

  if (typeof width === 'string') {
    return width;
  }

  return percentage(width);
};

export const isSmallScreen = (displayType: DisplayType) => {
  return (
    displayType === DisplayType.ExtraExtraSmall ||
    displayType === DisplayType.ExtraSmall ||
    displayType === DisplayType.Small ||
    displayType === DisplayType.Medium
  );
};

export const spacingCss = (theme: Theme, spaceKey: SpacingProp | undefined) => {
  if (!spaceKey) {
    return null;
  }

  const spacing = (key: DisplayType | null | 0 | 'auto') => {
    if (key === 0) {
      return 0;
    }

    if (key === 'auto') {
      return 'auto';
    }

    if (key !== null) {
      return isSmallScreen(key) ? theme.spacings['16'] : theme.spacings['24'];
    }

    return null;
  };

  return Array.isArray(spaceKey)
    ? spaceKey.map((value) => spacing(value))
    : spacing(spaceKey);
};
