import dayjs, { Dayjs } from 'dayjs';
import { wrapArrayIfNeeded } from '@utils/helperUtils';

export const constructSearchResultQueryPushParams = ({
  currency,
  departureDate,
  destinations,
  isOneWay,
  origins,
  paxTypeAges,
  residency,
  returnDate,
  utmCampaign,
  utmMedium,
  utmSource,
}: {
  currency?: string | null;
  departureDate: Dayjs;
  destinations: string[];
  isOneWay: boolean;
  origins: string[];
  paxTypeAges: { [paxType: string]: number[] | number };
  residency?: string | null;
  returnDate: Dayjs | null;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}) => ({
  pathname: '/search',
  query: {
    origins: origins.join(','),
    destinations: destinations.join(','),
    departureDate: dayjs(departureDate).format('YYYY-MM-DD'),
    returnDate:
      isOneWay || !returnDate ? null : dayjs(returnDate).format('YYYY-MM-DD'),
    isOneWay: (isOneWay || !returnDate).toString(),
    currency: currency ? currency : null,
    residency: residency ? residency : null,
    utm_source: utmSource,
    utm_medium: utmMedium || '',
    utm_campaign: utmCampaign || '',
    ...Object.keys(paxTypeAges).reduce<{ [key: string]: string }>(
      (acc, paxType) =>
        paxTypeAges[paxType]
          ? {
              ...acc,
              [paxType]: (
                wrapArrayIfNeeded(paxTypeAges[paxType]) as number[]
              ).join(','),
            }
          : acc,
      {},
    ),
  },
});
