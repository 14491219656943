import { GetStaticPropsContext } from 'next/types';
import { QueryClient } from '@tanstack/react-query';
import {
  useGetPassengersConfigQuery,
  useGetFooterConfigQuery,
  useGetBookingConfigQuery,
  useGetHeaderConfigQuery,
  useGetItineraryConfigQuery,
  useDohopConnectQuery,
  useGetPartnerExperimentQuery,
  useGetPartnersQuery,
} from '@codegen/cmsUtils';
import {
  ApplicationStringsV2QueryVariables,
  useCountriesQuery,
  useCurrenciesQuery,
  useApplicationStringsV2Query,
} from '@codegen/gatewayUtils';
import {
  Partner,
  Language,
  ApplicationStringNamespace,
  RUNTIME_ENV,
} from '@shared/types/enums';
import { parseApplicationStrings } from '@utils/applicationStringUtils';
import { defaultReactQueryClientOptions } from '@utils/queryUtils';
import { prefetchPartnerConfig } from '@utils/sharedQueries';
import { Experiment } from '@web/types/experimentTypes';
import { getExperimentDataFromCMS } from './experimentUtils';

export const DEFAULT_REVALIDATE_TIME = 60 * 60 * 24;

// Checks if a query is cached on the client, this can be used to avoid refetching queries during client side transitions
const checkIfQueryIsCached = <Variables>(queryKey: (string | Variables)[]) => {
  if (typeof window !== 'undefined') {
    return window.__NEXT_DATA__.props.dehydratedState.queries.find(
      (query: { queryHash: string }) => {
        return query.queryHash === JSON.stringify(queryKey);
      },
    );
  }

  return false;
};

export const getApplicationStrings = async ({
  locale,
  partner,
  queryClient,
}: {
  locale: Language;
  partner: Partner;
  queryClient: QueryClient;
}) => {
  const namespaces = [ApplicationStringNamespace.Shared, partner];
  const queryKey = useApplicationStringsV2Query.getKey({
    locale,
    namespaces,
  });

  if (checkIfQueryIsCached<ApplicationStringsV2QueryVariables>(queryKey)) {
    return;
  }

  const applicationStringsResult = await queryClient.fetchQuery(
    useApplicationStringsV2Query.getKey({ namespaces, locale }),
    async () =>
      await useApplicationStringsV2Query.fetcher({
        namespaces,
        locale,
      })(),
    { retry: 2 },
  );

  return parseApplicationStrings(applicationStringsResult);
};

export const prefetchUserSettingsConfig = async ({
  language,
  queryClient,
}: {
  language: Language;
  queryClient: QueryClient;
}) => {
  await queryClient.prefetchQuery(
    useCountriesQuery.getKey({ language }),
    async () => await useCountriesQuery.fetcher({ language })(),
    { retry: 2 },
  );

  await queryClient.prefetchQuery(
    useCurrenciesQuery.getKey({ language }),
    async () => await useCurrenciesQuery.fetcher({ language })(),
    { retry: 2 },
  );
};

export const prefetchBookingSteps = async ({
  locale,
  partner,
  queryClient,
}: {
  locale: Language;
  partner: string;
  queryClient: QueryClient;
}) => {
  const steps = await queryClient.fetchQuery(
    useGetBookingConfigQuery.getKey({ partner, locale }),
    async () => await useGetBookingConfigQuery.fetcher({ partner, locale })(),
  );

  return steps.partner?.partnerBookingConfig;
};

export const fetchExperimentData = async ({
  experimentKeys,
  locale,
  partner,
  queryClient,
}: {
  experimentKeys: Experiment[];
  locale: Language;
  partner: string;
  queryClient: QueryClient;
}) => {
  const data = await queryClient.fetchQuery(
    useGetPartnerExperimentQuery.getKey({ partner, locale }),
    async () =>
      await useGetPartnerExperimentQuery.fetcher({ partner, locale })(),
    { staleTime: Infinity },
  );

  const experimentData = getExperimentDataFromCMS({
    partnerExperiment: data.partner,
    experiments: experimentKeys,
  });

  return experimentData?.bookingConfig;
};

export const doesNotRequireSecret = (route: string) => {
  return route === '/' || route === '/search';
};

export const defaultGetStaticProps = async (
  context: GetStaticPropsContext,
  experimentKeys: Experiment[] = [],
) => {
  const { locale: lang, params } = context;

  const partner = (params?.partner as Partner | undefined) || Partner.Dohop;

  const queryClient = new QueryClient({
    defaultOptions: defaultReactQueryClientOptions,
  });

  const locale = lang === 'default' ? Language.English : (lang as Language);

  await fetchExperimentData({
    queryClient,
    partner,
    locale,
    experimentKeys,
  });

  await prefetchBookingSteps({ queryClient, partner, locale });

  await prefetchPartnerConfig({ partner, queryClient });

  await prefetchUserSettingsConfig({
    language: locale,
    queryClient,
  });

  // We only need this data prefetched on the server otherwise it is render blocking
  // for the client. This data is cached on the client so we don't need to refetch this data

  await queryClient.prefetchQuery(
    useGetFooterConfigQuery.getKey({ partner, locale }),
    async () => await useGetFooterConfigQuery.fetcher({ partner, locale })(),
    { retry: 2 },
  );

  await queryClient.prefetchQuery(
    useGetHeaderConfigQuery.getKey({ partner, locale }),
    async () => await useGetHeaderConfigQuery.fetcher({ partner, locale })(),
    { retry: 2 },
  );

  await queryClient.prefetchQuery(
    useGetPassengersConfigQuery.getKey({ partner, locale }),
    async () =>
      await useGetPassengersConfigQuery.fetcher({ partner, locale })(),
    { retry: 2 },
  );

  await queryClient.prefetchQuery(
    useGetItineraryConfigQuery.getKey({ partner }),
    async () => await useGetItineraryConfigQuery.fetcher({ partner })(),
    { retry: 2 },
  );

  await queryClient.prefetchQuery(
    useDohopConnectQuery.getKey({ locale }),
    async () => await useDohopConnectQuery.fetcher({ locale })(),
    { retry: 2 },
  );

  await getApplicationStrings({
    partner,
    locale,
    queryClient,
  });

  return {
    queryClient,
  };
};

export const getPartnerPaths = async () => {
  if (process.env.NEXT_PUBLIC_RUNTIME_ENV !== RUNTIME_ENV.production) {
    return [];
  }

  const queryClient = new QueryClient({
    defaultOptions: defaultReactQueryClientOptions,
  });

  const data = await queryClient.fetchQuery(
    useGetPartnersQuery.getKey(),
    async () => await useGetPartnersQuery.fetcher()(),
    { retry: 2 },
  );

  return Object.values(Partner)
    .filter((partner) => partner !== Partner.DohopConnect)
    .flatMap((partner) => {
      const partnerLocales =
        data.allPartners.find(
          (queryPartner) => (queryPartner.name as Partner) === partner,
        )?.userSettingsConfig?.supportedLanguages ?? [];

      return Object.values(Language)
        .filter((locale) => partnerLocales.find((lang) => lang.code === locale))
        .map((locale) => ({
          params: { partner },
          locale,
        }));
    });
};

export const defaultGetStaticPaths = async () => {
  const paths = await getPartnerPaths();

  return {
    paths,
    fallback: 'blocking', // false or "blocking"
  };
};
