import { css } from '@emotion/react';
import { mqPrint } from './base';

export const cssReset = css`
  /* 1. Use a more-intuitive box-sizing model. */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  /*  2. Remove default margin */
  * {
    margin: 0;
  }

  /*  3. Allow percentage-based heights in the application */
  /* stylelint-disable-next-line selector-id-pattern */
  #__next,
  .app {
    height: 100%;
  }

  /*  Typographic tweaks! */

  /*  4. Add accessible line-height */

  /*  5. Improve text rendering */
  body {
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
  }

  /*  6. Improve media defaults */
  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  /*  7. Remove built-in form typography styles */
  input,
  button,
  textarea,
  select {
    color: inherit;
    font: inherit;
  }

  /*  8. Avoid text overflows */
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
    white-space: normal;
  }

  .aux {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }

  ${mqPrint} {
    @page {
      margin: 0;
    }

    html {
      filter: opacity(1);
    }

    body {
      margin: 1.6cm;
    }
  }
`;

export const resetButton = css`
  padding: 0;
  border: none;
  margin: 0;
  appearance: none;
  background: transparent;
  line-height: inherit;
`;
