import { createContext, ReactNode, useContext } from 'react';
import { CurrencyFragment, useCurrenciesQuery } from '@codegen/gatewayUtils';
import { Language, CurrencyCode } from '@shared/types/enums';
import { parseQueryString } from '@utils/queryUtils';
import { getMainCurrencies } from '@web/utils/settingsUtils';
import { useConstants } from './ConstantContext';
import usePartnerInfo from './hooks/usePartnerInfo';
import usePartnerRouter from './hooks/usePartnerRouter';

type Languages = { code: Language; name: string }[];

export type Settings = {
  currencies: CurrencyFragment[];
  currency: CurrencyCode;
  hideCurrencySelection?: Maybe<boolean>;
  hideResidencySelection?: Maybe<boolean>;
  languages: Languages;
  mainCurrencies: CurrencyFragment[];
  residency: string;
  setCurrAndRes: (currency: CurrencyCode, residency: string) => void;
};

const DEFAULT_STATE: Settings = {
  currencies: [],
  mainCurrencies: [],
  languages: [],
  currency: CurrencyCode.EUR,
  residency: 'UK',
  setCurrAndRes: () => {},
};

const context = createContext<Settings>(DEFAULT_STATE);

export default context;

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const {
    push,
    query: { currency: queryCurrency, residency: queryResidency },
  } = usePartnerRouter();

  const { userSettingsConfig } = usePartnerInfo();

  const residency = queryResidency
    ? parseQueryString(queryResidency).toUpperCase()
    : (userSettingsConfig?.defaultResidency as string);

  const currency = (queryCurrency ||
    userSettingsConfig?.defaultCurrencyCode ||
    CurrencyCode.EUR) as CurrencyCode;

  const { locale } = useConstants();

  const { data: currencyData } = useCurrenciesQuery(
    {
      language: locale,
    },
    { placeholderData: { currencies: [] }, retry: 2 },
  );

  const currencies = currencyData?.currencies ?? [];

  const setCurrAndRes = (newCurrency: CurrencyCode, newResidency: string) => {
    if (newCurrency !== currency || newResidency !== residency) {
      push({
        query: { currency: newCurrency, residency: newResidency },
        shallow: true,
      });
    }
  };

  return (
    <context.Provider
      value={{
        currencies,
        languages: userSettingsConfig?.supportedLanguages as Languages,
        currency,
        residency,
        mainCurrencies: getMainCurrencies(
          currencies,
          userSettingsConfig?.mainCurrencies,
        ),
        hideCurrencySelection: userSettingsConfig?.hideCurrencySelection,
        hideResidencySelection: userSettingsConfig?.hideResidencySelection,
        setCurrAndRes,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useSettings = () => useContext(context);
