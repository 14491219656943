import dynamic from 'next/dynamic';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DisplayType } from '@shared/types/enums';
import HeaderBar from '@ui/components/HeaderBar/HeaderBar';
import Image from '@ui/components/Image';
import Link from '@ui/components/Link/Link';
import MediaQuery from '@ui/components/Responsive/MediaQuery';
import { legacySpacings } from '@ui-v2/theme/layout';
import { getDropdownValue } from '@utils/dropdownUtils';
import { getVendorLink } from '@utils/vendorUtils';
import { useConstants } from '@web/context/ConstantContext';
import { useCountries } from '@web/context/hooks/useCountries';
import usePartnerHeader from '@web/context/hooks/usePartnerHeader';
import usePartnerInfo from '@web/context/hooks/usePartnerInfo';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import { useSettings } from '@web/context/SettingsContext';

const Settings = dynamic(() => import('@ui/components/Settings/Settings'), {
  ssr: false,
  loading: () => null,
});

const ImageWrapper = styled.span<{ logoWidth?: Maybe<number> }>(
  ({ logoWidth }) => css`
    display: flex;
    width: ${logoWidth || 210}px;
    cursor: pointer;

    > img {
      height: auto;
      max-height: ${!logoWidth ? '50px' : 'initial'};
      object-position: left;
    }
  `,
);

const ExternalLinkWrapper = styled.span(
  () => css`
    margin-right: ${legacySpacings.Medium}px;
    color: white;
    font-weight: bold;
    text-decoration: underline;
  `,
);

const RightHandWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
);

const HeaderContainer = () => {
  const { locale } = useConstants();
  const { getPartnerHref, route } = usePartnerRouter();
  const { partnerWebsiteUrl, platformName } = usePartnerInfo();
  const headerData = usePartnerHeader();
  const countries = useCountries();

  const {
    currencies,
    currency,
    hideCurrencySelection,
    hideResidencySelection,
    languages,
    mainCurrencies,
    residency,
  } = useSettings();

  const partnerWebsite = getVendorLink({
    links: partnerWebsiteUrl,
    locale,
    residency,
  });

  const externalLink = getVendorLink({
    links: headerData?.faqLinks,
    locale,
    residency,
  });

  return (
    <HeaderBar image={headerData?.headerBarImage?.asset}>
      {headerData?.logo.url && (
        <Link
          ariaLabel={partnerWebsite?.label || platformName?.value}
          href={
            headerData.logoLinkToPartnerPage && partnerWebsite?.link
              ? partnerWebsite.link
              : '/'
          }
          target="_self"
        >
          <ImageWrapper logoWidth={headerData.logoWidth}>
            <Image
              alt={headerData.logo.alt}
              height={headerData.logo.height}
              objectFit="contain"
              priority
              src={headerData.logo.url}
              width={headerData.logo.width}
            />
          </ImageWrapper>
        </Link>
      )}
      <RightHandWrapper>
        <MediaQuery fromDisplay={DisplayType.Small}>
          {externalLink?.link && (
            <Link href={externalLink.link} target="_blank">
              <ExternalLinkWrapper>{externalLink.label}</ExternalLinkWrapper>
            </Link>
          )}
        </MediaQuery>

        <Settings
          countries={getDropdownValue(countries)}
          currencies={getDropdownValue(currencies)}
          currency={currency}
          getHref={getPartnerHref}
          hideCurrencySelection={hideCurrencySelection}
          hideResidencySelection={hideResidencySelection}
          languages={getDropdownValue(languages)}
          locale={locale}
          mainCurrencies={getDropdownValue(mainCurrencies)}
          residency={residency}
          route={route}
        />
      </RightHandWrapper>
    </HeaderBar>
  );
};

export default HeaderContainer;
