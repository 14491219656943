import Box from '../Box/Box';

type Props = {
  children?: React.ReactNode;
};

const Container = (props: Props) => {
  return (
    <Box
      maxWidth="container"
      mx={['auto']}
      px={[16, 16, 24, 24, 24, 24, 0]}
      width="100%"
      {...props}
    />
  );
};

export default Container;
