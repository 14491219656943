import { Interpolation, Theme } from '@emotion/react';
import {
  Document,
  isHeading,
  isLink,
  isParagraph,
  Node,
  Record,
  StructuredText as StructuredTextType,
} from 'datocms-structured-text-utils';
import { renderNodeRule, StructuredText } from 'react-datocms';
import Link from '@ui-v2/core/Link/Link';
import Text from '@ui-v2/core/Text/Text';

interface Props {
  className?: string;
  data?:
    | Document
    | Node
    | StructuredTextType<Record, Record>
    | null
    | undefined;
  h1Styles?: Interpolation<Theme>;
  h2Styles?: Interpolation<Theme>;
  h3Styles?: Interpolation<Theme>;
  h4Styles?: Interpolation<Theme>;
  h5Styles?: Interpolation<Theme>;
  h6Styles?: Interpolation<Theme>;
  linkStyles?: Interpolation<Theme>;
}

const StructuredTextWrapper = ({ data }: Props) => {
  return (
    <div>
      <StructuredText
        customNodeRules={[
          renderNodeRule(isLink, ({ children, key, node }) => {
            return (
              <Link
                href={node.url}
                key={key}
                {...node.meta?.reduce(
                  (acc, { id, value }) => ({ ...acc, [id]: value }),
                  {},
                )}
              >
                {children}
              </Link>
            );
          }),
          renderNodeRule(isHeading, ({ children, key, node }) => {
            if (node.level === 1) {
              return (
                <Text as="h1" key={key} variant="heading-1">
                  {children}
                </Text>
              );
            }

            if (node.level === 2) {
              return (
                <Text as="h2" key={key} variant="heading-2">
                  {children}
                </Text>
              );
            }

            if (node.level === 3) {
              return (
                <Text as="h3" key={key} variant="heading-3">
                  {children}
                </Text>
              );
            }

            if (node.level === 4) {
              return (
                <Text as="h4" key={key} variant="heading-4">
                  {children}
                </Text>
              );
            }

            if (node.level === 5) {
              return (
                <Text as="h5" key={key} variant="heading-5">
                  {children}
                </Text>
              );
            }

            return (
              <Text as="h6" key={key} variant="heading-6">
                {children}
              </Text>
            );
          }),
          renderNodeRule(isParagraph, ({ children, key }) => {
            return (
              <Text as="div" key={key} variant="body-1">
                {children}
              </Text>
            );
          }),
        ]}
        data={data}
      />
    </div>
  );
};

export default StructuredTextWrapper;
