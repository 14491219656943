import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { DisplayType } from '@shared/types/enums';
import { shouldForwardProp } from '../../styles/base';
import {
  DisplayProp,
  ResponsiveProp,
  WrapProp,
  DirectionProp,
  AlignProp,
  JustifyProp,
  WidthProp,
  OrderProp,
  SpacingProp,
  TextAlignProp,
  FlexProp,
  GapProp,
  GridTemplateColumnsProps,
} from './gridTypes';
import { mq, alignCss, widthCss, justifyCss, spacingCss } from './gridUtils';

export interface GridWrapProps {
  display?: DisplayProp;
  maxWidth?: ResponsiveProp<number | string>;
}

export const GridWrap = styled('div', {
  shouldForwardProp: (prop) =>
    shouldForwardProp({ prop, excludeProps: ['display'] }),
})<GridWrapProps>(({ display, maxWidth: propMaxWidth, theme }) => {
  return mq()({
    display: display || null,
    width: '100%',
    margin: 'auto',
    maxWidth: propMaxWidth || theme.sizes.container,
  });
});

interface LayoutProps {
  display?: DisplayProp;
  gap?: GapProp;
  justifyItems?: JustifyProp;
  my?: SpacingProp;
  px?: SpacingProp;
}

export interface GridRowProps extends LayoutProps {
  align?: AlignProp;
  direction?: DirectionProp;
  justify?: JustifyProp;
  wrap?: WrapProp;
}

export const GridRow = styled('div', {
  shouldForwardProp: (prop) =>
    shouldForwardProp({ prop, excludeProps: ['wrap', 'direction', 'display'] }),
})<GridRowProps>(({
  align,
  direction,
  display,
  gap,
  justify,
  my,
  px,
  theme,
  wrap,
}) => {
  return mq()({
    display: display || 'flex',
    flexWrap: wrap || null,
    flexDirection: direction || null,
    alignItems: alignCss(align),
    justifyContent: justify ? justifyCss(justify) : null,
    gap: gap || null,
    paddingLeft: spacingCss(theme, px),
    paddingRight: spacingCss(theme, px),
    marginTop: spacingCss(theme, my),
    marginBottom: spacingCss(theme, my),
  });
});

export interface LayoutGridProps extends LayoutProps {
  gridTemplateColumns?: GridTemplateColumnsProps;
}

export const LayoutGrid = styled.div<LayoutGridProps>(
  ({ gap, gridTemplateColumns, justifyItems, my, px, theme }) => {
    return mq()({
      display: 'grid',
      gridTemplateColumns: gridTemplateColumns || 'auto',
      gap,
      paddingLeft: spacingCss(theme, px),
      paddingRight: spacingCss(theme, px),
      marginTop: spacingCss(theme, my),
      marginBottom: spacingCss(theme, my),
      justifyItems,
    });
  },
);

export interface BaseGridColumnProps {
  align?: AlignProp;
  className?: string;
  direction?: DirectionProp;
  display?: DisplayProp;
  flex?: FlexProp;
  gap?: GapProp;
  justify?: JustifyProp;
  m?: SpacingProp;
  mb?: SpacingProp;
  ml?: SpacingProp;
  mr?: SpacingProp;
  mt?: SpacingProp;
  mx?: SpacingProp;
  my?: SpacingProp;
  offset?: WidthProp;
  order?: OrderProp;
  p?: SpacingProp;
  pb?: SpacingProp;
  pl?: SpacingProp;
  pr?: SpacingProp;
  pt?: SpacingProp;
  px?: SpacingProp;
  py?: SpacingProp;
  textAlign?: TextAlignProp;
  width?: WidthProp;
}

export const BaseGridColumn = styled('div', {
  shouldForwardProp: (prop) =>
    shouldForwardProp({
      prop,
      excludeProps: ['width', 'display', 'offset', 'direction'],
    }),
})<BaseGridColumnProps>(({
  align,
  direction,
  display,
  flex,
  gap,
  justify,
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  order,
  p,
  pb,
  pl,
  pr,
  pt,
  px,
  py,
  textAlign,
  theme,
  width,
}) => {
  const getFlexValue = (flex?: FlexProp, width?: WidthProp) => {
    if (flex !== undefined) {
      return flex;
    }

    if (width !== undefined) {
      return null;
    }

    return '1 0 auto';
  };

  return mq()({
    display: display || null,
    flex: getFlexValue(flex, width),
    width: widthCss(width),
    order: order !== undefined ? order : null,
    alignSelf: alignCss(align),
    textAlign: textAlign || null,
    padding: spacingCss(theme, p),
    paddingTop: spacingCss(theme, pt ?? py),
    paddingRight: spacingCss(theme, pr ?? px),
    paddingBottom: spacingCss(theme, pb ?? py),
    paddingLeft: spacingCss(theme, pl ?? px),
    margin: spacingCss(theme, m),
    marginTop: spacingCss(theme, mt ?? my),
    marginRight: spacingCss(theme, mr ?? mx),
    marginBottom: spacingCss(theme, mb ?? my),
    marginLeft: spacingCss(theme, ml ?? mx),
    flexDirection: direction ?? null,
    justifyContent: justify ? justifyCss(justify) : null,
    gap: gap || null,
  });
});

export const GridColumn = (
  props: BaseGridColumnProps & {
    children: ReactNode;
  },
) => (
  <BaseGridColumn
    px={
      props.px || [
        DisplayType.ExtraExtraSmall,
        DisplayType.ExtraSmall,
        DisplayType.Small,
        DisplayType.Medium,
        DisplayType.Large,
        DisplayType.ExtraLarge,
        DisplayType.ExtraExtraLarge,
      ]
    }
    {...props}
  >
    {props.children}
  </BaseGridColumn>
);
