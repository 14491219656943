import { IconConfigFragment, ImageWithConfigFragment } from '@codegen/cmsUtils';
import useDohopConnect from './useDohopConnect';
import usePartnerInfo from './usePartnerInfo';

// TODO: Create a shared context lib and move this functionality
// We try to get the icon through the partners iconConfig, if the partner doesn't have one we use the dohop one
const useGetIcon = () => {
  const { iconConfig } = usePartnerInfo();
  const { dohopIconConfig } = useDohopConnect();

  const getIcon = (iconIdentifier: keyof IconConfigFragment) => {
    if (
      (!iconConfig && !dohopIconConfig) ||
      (!iconConfig?.[iconIdentifier] && !dohopIconConfig?.[iconIdentifier])
    ) {
      return null;
    }

    return (iconConfig?.[iconIdentifier] ||
      dohopIconConfig?.[iconIdentifier]) as ImageWithConfigFragment;
  };

  return getIcon;
};

export default useGetIcon;
