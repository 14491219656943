import dayjs from 'dayjs';
import { Translate } from 'next-translate';
import Trans from 'next-translate/Trans';
import { Vendors } from '@codegen/cmsTypes';
import {
  BundleFragment,
  IconConfigFragment,
  ImageWithConfigFragment,
} from '@codegen/cmsUtils';
import {
  BookedAdditionalLoungeAccess,
  Leg,
  LegGroupSummary,
  Summary,
  SummaryOtherServicesItem,
} from '@codegen/offerAPI';
import { Language, RUNTIME_ENV } from '@shared/types/enums';
import { findVendorByIata } from '@ui/utils/vendorUtils';
import TranslatedLink from '@ui-v2/core/Link/Link';
import Text from '@ui-v2/core/Text/Text';
import { getTimeStringFromDayjs, toUTCLocaleString } from '@utils/dateUtils';
import { BundleService } from '@utils/sharedServiceUtils';
import { getCityNamesFromLegs } from './bookingUtils';
import { constructCombinedServices } from './serviceUtils';

export const getBundleName = ({
  bundleCode,
  bundleConfig,
  legs,
}: {
  bundleCode: string;
  bundleConfig?: BundleFragment[];
  legs?: Leg[];
}) => {
  const selectedBundle = bundleConfig?.find(
    (bundle) => bundle.bundleId?.includes(bundleCode),
  );

  const fallbackAirlines = legs?.map(
    (itinerary) => itinerary.marketing_carrier.name,
  );

  const fallbackAirlineFiltered = [...new Set(fallbackAirlines)].join(', ');

  return selectedBundle?.name ?? (fallbackAirlineFiltered || bundleCode);
};

// returns the itinerary as it is displayed in the summary
export const getItineraryItems = (legs: Leg[]) => {
  return {
    leg_id: legs[0]?.leg_id,
    origin: legs[0]?.origin,
    destination: legs[legs.length - 1]?.destination,
    is_outbound: legs[0]?.is_outbound,
    marketing_carrier: legs[0]?.marketing_carrier,
    operating_carrier: legs[0]?.operating_carrier,
    flight_number: legs[0]?.flight_number,
    departure: legs[0]?.departure,
    arrival: legs[legs.length - 1]?.arrival,
  };
};

export const getIatasFromSummary = (summary?: Summary) => {
  if (!summary) {
    return [];
  }

  return summary.leg_summaries.reduce<string[]>((iatas, legSummary) => {
    const legIatas = legSummary.legs
      .reduce<string[]>((legAcc, leg) => {
        if (legAcc.includes(leg.marketing_carrier.code)) {
          return legAcc;
        }

        return [...legAcc, leg.marketing_carrier.code];
      }, [])
      .filter((iata) => !iatas.includes(iata));

    return [...iatas, ...legIatas];
  }, []);
};

export interface BundleInfo {
  code: string;
  includedServices: BundleService[];
  itinerary: ReturnType<typeof getItineraryItems>;
  legItineraryName: string;
  name: string;
}

export const getSelectedBundlesFromSummary = ({
  getFallbackIcon,
  summary,
  t,
  vendors,
}: {
  getFallbackIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  summary?: Summary;
  t: Translate;
  vendors: Vendors;
}) => {
  if (!summary) {
    return [];
  }

  const bundleCodes = summary.leg_summaries.reduce<string[]>(
    (acc, leg) =>
      acc.includes(leg.bundle.code) ? acc : [...acc, leg.bundle.code],
    [],
  );

  return bundleCodes
    .map((bundleCode) => {
      const bundleConfig = Object.values(vendors).find(
        (vendor) =>
          vendor.vendorBookingConfig?.bundles.find(
            (bundle) => bundle.bundleId?.includes(bundleCode),
          ),
      )?.vendorBookingConfig?.bundles;

      const legSummary = summary.leg_summaries.find(
        (leg) => leg.bundle.code === bundleCode,
      );

      if (legSummary) {
        const itinerary = getItineraryItems(legSummary.legs);

        const cmsServices = findVendorByIata(
          vendors,
          itinerary.marketing_carrier?.code,
        )?.vendorBookingConfig?.servicesConfig?.services;

        const includedServices = constructCombinedServices({
          offerServices: legSummary.included_services,
          cmsServices: cmsServices || [],
          t,
          getFallbackIcon,
        });

        const legItineraryName = getCityNamesFromLegs(legSummary.legs);

        return {
          code: bundleCode,
          name: getBundleName({
            bundleCode,
            bundleConfig,
            legs: legSummary.legs,
          }),
          legItineraryName,
          includedServices,
          itinerary,
        };
      }

      return null;
    })
    .filter((item): item is BundleInfo => Boolean(item));
};

export const getOtherServiceName = ({
  alyziaGroundHandlingName,
  dohopServiceName,
  legGroups,
  protectGroupName,
  service,
  t,
}: {
  alyziaGroundHandlingName?: string;
  dohopServiceName?: string;
  legGroups?: LegGroupSummary[];
  protectGroupName?: string;
  service: SummaryOtherServicesItem;
  t: Translate;
}) => {
  switch (service.service_class) {
    case 'dohop_service_fee':
      return dohopServiceName;
    case 'alyzia':
      return alyziaGroundHandlingName;
    case 'cancellation_protection':
      return protectGroupName;

    case 'lounge_access': {
      const serviceLeg = legGroups
        ?.flatMap((leg) => leg.legs)
        .find(
          (leg) =>
            leg.flight_number ===
            (service as BookedAdditionalLoungeAccess).flight_number,
        );

      return `${t('airport_lounge_access', {
        default: 'Airport Lounge Access',
      })} ${t('at')} ${serviceLeg?.origin.airport_name} (${serviceLeg?.origin
        .airport_iata})`;
    }
    default:
      return t(service.service_class);
  }
};

export const getOtherServiceDescription = ({
  language,
  orderId,
  service,
  t,
}: {
  language: Language;
  orderId?: string;
  service: SummaryOtherServicesItem;
  t: Translate;
}) => {
  switch (service.service_class) {
    case 'lounge_access': {
      const lounge = service as BookedAdditionalLoungeAccess;
      const loungeEntry = dayjs(lounge.lounge_entry);

      return [
        ...(lounge.reference_id
          ? [
              {
                label: t('reference_id'),
                value: lounge.reference_id,
              },
            ]
          : []),
        ...(lounge.lounge_entry
          ? [
              {
                label: t('lounge_arrival_time'),
                value: `${toUTCLocaleString({
                  date: loungeEntry.toDate(),
                  locale: language,
                  options: {
                    day: '2-digit',
                    month: 'short',
                  },
                })} ${t('at')} ${getTimeStringFromDayjs(
                  loungeEntry,
                  language,
                )}`,
              },
            ]
          : []),
      ];
    }
    case 'cancellation_protection':
      return [
        {
          label: t('reference_id'),
          value: orderId,
        },
        {
          label: t('reference_id'),
          value: (
            <Text as="span" variant="body-1">
              <Trans
                components={[
                  <TranslatedLink
                    href={`https://${
                      process.env.NEXT_PUBLIC_RUNTIME_ENV ===
                      RUNTIME_ENV.production
                        ? 'prod'
                        : 'test'
                    }.form.refundable.me/forms/refund?bookingReference=${orderId}&memberId=839`}
                    key="refund-link"
                    size="small"
                  />,
                ]}
                defaultTrans="Click <0>here</0> to request a refund"
                i18nKey="refund_request"
              />
            </Text>
          ),
        },
      ];
    default:
      return null;
  }
};
