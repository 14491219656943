import useEffectOnce from './useEffectOnce';
import useToggle from './useToggle';

const useTrustPilotWidget = () => {
  const [isLoading, toggleIsLoading] = useToggle(true);
  useEffectOnce(() => {
    const script = document.createElement('script');
    // eslint-disable-next-line functional/immutable-data
    script.src =
      'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    document.head.appendChild(script);

    // eslint-disable-next-line functional/immutable-data
    script.onload = toggleIsLoading;
  });

  return { isLoading };
};

export default useTrustPilotWidget;
