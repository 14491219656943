import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { LinkSize, LinkVariant } from '@ui-v2/types/buttons';
import { ThemeMarginProps } from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { buildMargin } from '@ui-v2/utils/themePropBuilders';
import { buildLinkSize, buildLinkVariant } from './variants';

export const TRANSITION_SPEED = '200ms';

export type LinkInherentProps = ThemeMarginProps & {
  size?: LinkSize;
  underlineStyle?: 'standard' | 'stylized' | 'none';
  variant?: LinkVariant;
};

export const stylizedUnderlineStyles = css`
  text-decoration: none;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    transform: translateY(0);
    transition:
      transform ${TRANSITION_SPEED},
      background-color ${TRANSITION_SPEED};
  }

  &:hover::after {
    transform: translateY(-1px);
  }
`;

export type LinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof NextLinkProps
> &
  NextLinkProps &
  LinkInherentProps;

const StyledLink = styled(NextLink, {
  shouldForwardProp: (prop) => prop !== 'underlineStyle',
})<
  LinkProps & {
    size: NonNullable<LinkProps['size']>;
    variant: NonNullable<LinkProps['variant']>;
  }
>(({ size, theme, underlineStyle, variant, ...props }) => [
  css`
    position: relative;
    line-height: ${theme.typography.body01.lineHeightPercent}%;
    transition: color ${TRANSITION_SPEED};
  `,
  underlineStyle === 'stylized' && stylizedUnderlineStyles,
  css(
    buildResponsiveValues({
      ...buildMargin(props),
      ...buildLinkVariant(variant, theme),
      ...buildLinkSize(size),
    }),
  ),
]);

const Link = ({
  size = 'regular',
  target = '_blank',
  underlineStyle = 'stylized',
  variant = 'primary',
  ...props
}: LinkProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <StyledLink
      {...props}
      size={size}
      target={target}
      underlineStyle={underlineStyle}
      variant={variant}
    />
  );
};

export default Link;
