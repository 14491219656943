import { Theme } from '@emotion/react';
import { TypographyV4Fragment } from '@codegen/cmsUtils';
import { ThemeResponsiveProp } from '@ui-v2/types/props';
import { TextVariant, TypographyVariant } from '@ui-v2/types/typography';
import { TextLineHeightProp } from './Text';

export type TextVariantStyleConfig = {
  fontFamily: string;
  fontSize: ThemeResponsiveProp<number | string>;
  fontWeight: ThemeResponsiveProp<number>;
  letterSpacing?: ThemeResponsiveProp<number | string>;
  lineHeight: ThemeResponsiveProp<number | string | null | undefined>;
  textTransform?: string;
};

export const typographyVariantFragmentMapper: Record<
  TypographyVariant,
  keyof TypographyV4Fragment
> = {
  'body-1': 'body01',
  'body-2': 'body02',
  'body-3': 'body03',
  'display-1': 'display01',
  'display-2': 'display02',
  'display-3': 'display03',
  'heading-1': 'heading01',
  'heading-2': 'heading02',
  'heading-3': 'heading03',
  'heading-4': 'heading04',
  'heading-5': 'heading05',
  'heading-6': 'heading06',
  'heading-7': 'heading07',
};

export const buildTextVariant = (
  variant: TextVariant,
  theme: Theme,
  config: { lineHeight?: TextLineHeightProp },
): TextVariantStyleConfig => {
  const fragment = typographyVariantFragmentMapper[variant];

  return {
    fontSize: theme.typography[fragment].fontSize,
    fontFamily: theme.typography[fragment].fontFamily,
    fontWeight: theme.typography[fragment].fontWeight,
    lineHeight:
      config.lineHeight ?? `${theme.typography[fragment].lineHeightPercent}%`,
    letterSpacing: theme.typography[fragment].letterSpacing,
    textTransform: theme.typography[fragment].textTransform,
  };
};
