import { useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import rgba from 'polished/lib/color/rgba';
import { DisplayType, KeyboardKey } from '@shared/types/enums';
import useToggle from '../../hooks/useToggle';
import { GridWrap, GridRow, GridColumn } from './Grid';

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;
`;

const InnerColumn = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100vh;
    background-color: ${rgba(theme.colours.brand.primary, 0.1)};
  `,
);

const GridOverlay = () => {
  const [visible, toggleVisible] = useToggle();
  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === KeyboardKey.Escape) {
        toggleVisible();
      }
    };

    document.addEventListener('keyup', keyHandler);

    return () => {
      document.removeEventListener('keyup', keyHandler);
    };
  }, [visible, toggleVisible]);

  if (!visible) {
    return null;
  }

  return (
    <Wrapper>
      <GridWrap>
        <GridRow>
          {[...Array(12).keys()].map((i) => (
            <GridColumn
              key={i}
              px={[
                DisplayType.ExtraExtraSmall,
                DisplayType.ExtraSmall,
                DisplayType.Small,
                DisplayType.Medium,
                DisplayType.Large,
                DisplayType.ExtraLarge,
                DisplayType.ExtraExtraLarge,
              ]}
            >
              <InnerColumn />
            </GridColumn>
          ))}
        </GridRow>
      </GridWrap>
    </Wrapper>
  );
};

export default GridOverlay;
