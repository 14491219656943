import useTranslation from 'next-translate/useTranslation';
import { OfferResponse, usePatchOfferIdServices } from '@codegen/offerAPI';
import { ServiceClass } from '@shared/types/enums';
import { useAnalytics } from '@web/context/AnalyticsContext';
import { useErrorOrchestrator } from '@web/context/ErrorOrchestratorContext';
import useGetIcon from '@web/context/hooks/useGetIcon';
import useGetOffer from '@web/context/hooks/useGetOffer';
import useVendors from '@web/context/hooks/useVendors';
import { useSettings } from '@web/context/SettingsContext';
import {
  constructPassengersServices,
  updateOfferWithServiceQuantity,
} from '@web/utils/booking/bookingUtils';
import { getIatasFromSummary } from '@web/utils/booking/summaryUtils';
import { OfferMutations } from './useIsUpdating';
import { usePassengers } from './usePassengers';

const useServices = ({
  onSuccess,
  serviceClasses,
}: {
  onSuccess?: (offer: OfferResponse) => void;
  serviceClasses?: ServiceClass[];
} = {}) => {
  const getFallbackIcon = useGetIcon();
  const { t } = useTranslation();
  const { currency } = useSettings();
  const { passengers } = usePassengers();
  const { isOfferLoading, offer, setOffer } = useGetOffer();
  const carrierCodes = getIatasFromSummary(offer?.summary);
  const { showError } = useErrorOrchestrator();

  const { isLoading, vendors } = useVendors({
    iatas: carrierCodes,
    includeBookingData: true,
  });

  const { sendAddServiceEvent } = useAnalytics();

  const passengersServices = constructPassengersServices({
    t,
    serviceClasses,
    offer,
    vendors,
    getFallbackIcon,
    carrierCodes,
    passengers,
  });

  const { isLoading: isUpdatingQuantity, mutate } = usePatchOfferIdServices({
    mutation: {
      mutationKey: [OfferMutations.SERVICES],
      retry: 2,
      onMutate: ({ data }) => {
        const nextOffer = updateOfferWithServiceQuantity({
          offer: offer as OfferResponse,
          serviceId: data.service_id,
          quantity: data.quantity,
        });

        const previousOffer = offer;

        setOffer(nextOffer);

        return { previousOffer };
      },
      onError: (_error, _data, context?: { previousOffer?: OfferResponse }) => {
        showError({});

        if (context?.previousOffer) {
          setOffer(context.previousOffer);
        }
      },
      onSuccess: (data, variables) => {
        setOffer(data);
        onSuccess?.(data);
        sendAddServiceEvent(
          variables.data.service_id,
          data,
          variables.data.quantity,
        );
      },
    },
  });

  const changeServiceQuantity = (serviceId: string, quantity: number) =>
    mutate({
      offerId: offer?.offer_id as string,
      data: {
        service_id: serviceId,
        quantity,
      },
      params: { curr: currency },
    });

  return {
    isLoading: isOfferLoading || isLoading,
    isUpdatingQuantity,
    passengersServices,
    changeServiceQuantity,
  };
};

export default useServices;
