import { KeyboardKey } from '@shared/types/enums';
import { Item } from '../AutocompleteTypes';

const normalizeValue = (value: string) =>
  value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const checkIfItemIncludesInputValue = (value: string, inputValue: string) =>
  normalizeValue(value).includes(normalizeValue(inputValue));

const checkIfItemIsExactMatch = (item: Item, inputValue: string): boolean =>
  normalizeValue(item.value) === inputValue ||
  normalizeValue(item.code) === inputValue ||
  item.subItems.some((item) => checkIfItemIsExactMatch(item, inputValue));

export const filterItems = (items: Item[], inputValue: string): Item[] => {
  const matches = (item: Item) => {
    if (
      checkIfItemIncludesInputValue(item.value, inputValue) ||
      checkIfItemIncludesInputValue(item.code, inputValue)
    ) {
      return true;
    }

    return item.subItems.some(
      (subItem) =>
        checkIfItemIncludesInputValue(subItem.value, inputValue) ||
        checkIfItemIncludesInputValue(subItem.code, inputValue),
    );
  };

  return items.filter(matches).map((item) => ({
    ...item,
    subItems: item.subItems.filter(
      (subItem) =>
        checkIfItemIncludesInputValue(subItem.value, inputValue) ||
        checkIfItemIncludesInputValue(subItem.code, inputValue),
    ),
  }));
};

export const filterAndSort = (items: Item[], inputValue: string): Item[] => {
  const sortItems = (first: Item, second: Item) => {
    if (!inputValue) {
      return first.value.localeCompare(second.value);
    }

    const isFirstExact = checkIfItemIsExactMatch(first, inputValue);
    const isSecondExact = checkIfItemIsExactMatch(second, inputValue);

    if (isFirstExact && !isSecondExact) {
      return -1;
    }

    if (!isFirstExact && isSecondExact) {
      return 1;
    }

    const includesFirst =
      checkIfItemIncludesInputValue(first.value, inputValue) ||
      checkIfItemIncludesInputValue(first.code, inputValue);

    const includesSecond =
      checkIfItemIncludesInputValue(second.value, inputValue) ||
      checkIfItemIncludesInputValue(second.code, inputValue);

    if (includesFirst && !includesSecond) {
      return -1;
    }

    if (!includesFirst && includesSecond) {
      return 1;
    }

    return (second.rank || 0) - (first.rank || 0);
  };

  const processSubItems = (item: Item) => {
    if (
      checkIfItemIncludesInputValue(item.value, inputValue) ||
      checkIfItemIncludesInputValue(item.code, inputValue)
    ) {
      return {
        ...item,
        subItems: [...item.subItems].sort(sortItems),
      };
    }

    return {
      ...item,
      subItems: [...filterItems([...item.subItems], inputValue)].sort(
        sortItems,
      ),
    };
  };

  const meetsCriteria = (item: Item) =>
    checkIfItemIsExactMatch(item, inputValue) ||
    checkIfItemIncludesInputValue(item.value, inputValue) ||
    checkIfItemIncludesInputValue(item.code, inputValue) ||
    item.subItems.length > 0;

  return items.map(processSubItems).sort(sortItems).filter(meetsCriteria);
};

export const getFirstItem = (
  items: Item[],
  inputValue = '',
): Item | undefined =>
  items.reduce<Item | undefined>(
    (firstItem, item) =>
      firstItem ||
      (checkIfItemIsExactMatch(item, inputValue) ||
      checkIfItemIncludesInputValue(item.value, inputValue) ||
      checkIfItemIncludesInputValue(item.code, inputValue)
        ? item
        : getFirstItem(item.subItems, inputValue)),
    undefined,
  );

export const findItemByValue = (
  items: Item[],
  value: string,
): Item | undefined =>
  items.reduce<Item | undefined>((item, iterItem) => {
    if (item) {
      return item;
    }

    return value === iterItem.value &&
      !iterItem.subItems.some((subIterItem) => subIterItem.value === value)
      ? iterItem
      : findItemByValue(iterItem.subItems, value);
  }, undefined);

export const findItemByCode = (items: Item[], code: string): Item | undefined =>
  items.reduce<Item | undefined>((item, iterItem) => {
    if (item) {
      return item;
    }

    return code === iterItem.code
      ? iterItem
      : findItemByCode(iterItem.subItems, code);
  }, undefined);

export const findItemParentByCode = (
  items: Item[],
  codes: string[],
  parentItem?: Item,
): Item | undefined =>
  items.reduce<Item | undefined>((item, iterItem) => {
    if (item) {
      return item;
    }

    if (codes.includes(iterItem.code) && codes.length === 1) {
      return iterItem;
    }

    return codes.includes(iterItem.code) &&
      !iterItem.subItems.some((it) => codes.includes(it.code))
      ? parentItem
      : findItemParentByCode(iterItem.subItems, codes, iterItem);
  }, undefined);

export const getNewHighlightedIndex = ({
  allVisibleItems,
  highlightedIndex,
  isDownKey,
}: {
  allVisibleItems: NodeListOf<Element>;
  highlightedIndex: number;
  isDownKey: boolean;
}) => {
  if (isDownKey) {
    return allVisibleItems.length - 1 === highlightedIndex
      ? 0
      : highlightedIndex + 1;
  }

  return highlightedIndex === 0
    ? allVisibleItems.length - 1
    : highlightedIndex - 1;
};

export const getUpdatedHighlightedValues = ({
  event,
  highlightedIndex,
  id,
}: {
  event: React.KeyboardEvent<HTMLInputElement>;
  highlightedIndex: number;
  id: string;
}): [number, string | null] => {
  event.preventDefault();

  const allVisibleItems: NodeListOf<Element> = document.querySelectorAll(
    `[data-id='${id}-value']`,
  );

  const newHighligtedIndex = getNewHighlightedIndex({
    isDownKey: event.key === KeyboardKey.ArrowDown,
    allVisibleItems,
    highlightedIndex,
  });

  const newValue = allVisibleItems.item(newHighligtedIndex).textContent ?? null;

  return [newHighligtedIndex, newValue];
};
