import { useState } from 'react';

const useToggle = (defaultValue = false) => {
  const [isActive, setState] = useState(defaultValue);
  const toggle = () => setState(!isActive);

  return [isActive, toggle] as const;
};

export default useToggle;
