import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactComponent as DohopConnectFigureSvg } from './dohopConnectFigure.svg';

const StyleWrapper = styled.div(
  ({ theme }) => css`
    #land {
      fill: ${theme.colours.border.success};
    }
  `,
);

const DohopConnectFigure = () => {
  return (
    <StyleWrapper>
      <DohopConnectFigureSvg />
    </StyleWrapper>
  );
};

export default DohopConnectFigure;
